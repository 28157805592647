import React from "react";
import Avatar from "../assets/images/chat-image.png"

const NeedHelp = ({handleExitHelpModal, handleChatBox}) => {
  return (
    <div className="fixed bottom-[150px] right-0 md:right-6 w-full md:w-[30vw]  bg-[#00325E] h-auto rounded-[20px] z-50 flex flex-col items-center p-8 gap-6">
     <img src={Avatar} alt="Avatar" className="w-[40px] h-[40px]" />
     <div className="flex flex-col items-center gap-2 ">
        <h1 className="text-[#F1F1F1] font-[700] text-[26px] leading-[34px] text-center">Need help?</h1>
        <p className="text-helpParagraph font-[400] text-[16px] leading-[20px] text-center">No problem. Chat with our sales
        experts or call us at 1-000-666-8975.</p>
     </div>
     <div className="flex gap-[56px] items-center">
        <button type="button" onClick={handleChatBox} className="bg-[#FFFFFF] p-4 rounded-[4px] border-none flex justify-center items-center font-[500] text-[16px] leading-[21px] text-[#00325E] text-center">Ask a Question?</button>
        <button type="button" onClick={handleExitHelpModal} className="text-[#FAFDFF] font-[600] text-[16px] leading-[21px] underline text-center ">No, Thanks</button>
     </div>
    </div>
  );
};

export default NeedHelp;
