import React from 'react'
import allFeatures from '../utils/features';
import "react-lazy-load-image-component/src/effects/blur.css";
// import { LazyLoadImage } from "react-lazy-load-image-component";

const Features = () => {
  // const LazyFeatureImage = ({ src, alt }) => {
  //   return <LazyLoadImage alt={alt} src={src} effect="blur" width={'100%'} />;
  // };
  return (
    <div className='mt-[160px] lg:mt-[176px] font-dmsans max-w-full overflow-hidden '>
        <div className='flex items-center justify-center'>
        <div className='lg:w-[600px] flex flex-col items-center gap-[6px] px-5'>
            <h1 className='text-spaceGrotesk font-[700] md:font-[500] text-[24px] md:text-[32px] leading-[40px] -tracking-[0.3px] text-black text-center'>All Features</h1>
            <p className='font-[400] text-[14px] leading-[20px] md:text-[16px] md:leading-[24px] tracking-[0.3px] text-center text-[#48494D]'>From streamlining operations to enhancing efficiency, Hamzaa ensures
            your shop runs smoothly, allowing you to focus on growth.</p>
            </div>
        </div>
        <div className='flex flex-col lg:flex-row justify-center flex-wrap gap-6 px-6 mt-[68px] mb-[60px] lg:gap-[40px] lg:px-[80px] lg:mt-[60px] lg:mb-[80px]'>
            {allFeatures.map((feature, index) => (
<div   data-aos="zoom-out" key={index} className='flex flex-col gap-4 p-[14px] lg:p-6 w-full lg:w-[40%] border rounded-[15px] bg-[#FFFFFF] transform transition-transform duration-300 hover:scale-105 hover:border-foot hover:bg-[#F6F9FF]'>
<img src={feature.image} alt={`${feature.title}`} className='w-full'  />
    <h2 className='mt-[8px] font-spaceGrotesk text-[16px] md:text-[24px] leading-[20px] md:leading-[32px] text-[#161414] font-[700]'>{feature.title}</h2>
    <p className='font-[400] text-[12px] leading-[18px] md:text-[18px] md:leading-[28px] tracking-[0.09px] text-[#616161]'>{feature.description}</p>
</div>
            ))}
        </div>
    </div>
  )
}

export default Features