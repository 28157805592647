import React, { useState } from "react";
import Avatar from "../assets/images/chat-image.png";
import { IoMdSend } from "react-icons/io";

const AskQuestionModal = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");


  const sendMessage = () => {
    if (newMessage.trim()) {
      setMessages([
        ...messages,
        { id: Date.now(), text: newMessage, sender: "user" },
      ]);
      setNewMessage("");
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    sendMessage();
  };
  const handleKeyDown = (e) => {
    
    if (e.key === "Enter") {
        e.preventDefault();
      sendMessage();
    }
  };
  return (
    <div className="fixed bottom-[200px] right-0 md:right-6 z-50 w-full h-[350px] md:w-[30vw]  bg-white">
    <div className=" rounded-[6px]  shadow-lg w-full ">
      <div
        className={` flex justify-between items-center bg-white rounded-t-[6px]  `}
      >
        <div className="flex items-center  py-[10px] px-[10px]">
          <img
            src={Avatar}
            alt="Avatar"
            className="w-[35px] h-[35px] md:w-[40px] md:h-[40px]"
          />
          <h1 className="mx-[10px] text-[#6B6666] font-[400] text-[10px] md:text-[12px] leading-[18px]">
            Blessing from Hamzaa
          </h1>
        </div>
      </div>
      <div
        className={`bg-white h-[280px] overflow-y-auto scroll-smooth  pt-[68px] pb-[68px] scrollbar-thin scrollbar-thumb-scrollbar-thumb hover:scrollbar-thumb-scrollbar-thumb-hover `}
      >
      
        {messages.map((message) => (
          <div
            key={message.id}
            className={`p-2 my-2 ${
              message.sender === "user" ? "text-right" : "text-left"
            }`}
          >
            <span
              className={`inline-block p-2 mx-2 rounded max-w-full break-words ${
                message.sender === "user"
                  ? "bg-[#00325E] text-white text-left"
                  : "bg-gray-300 text-black text-right "
              }`}
            >
              {message.text}
            </span>
          </div>
        ))}
      </div>
      <form
      onSubmit={handleFormSubmit}
        className={`flex flex-col p-2 h-auto w-full bg-white rounded-b-[6px] relative`}
      >
          <input
            type="text"
            className="w-full py-3 px-[18px] border-[1px] border-[#949292] rounded-[10px] outline-[#00325E] text-black font-[400] text-[12px] md:text-[16px] leading-[11px]"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Write a reply..."
          />
          <button
            type="submit"
            onClick={sendMessage}
            className="w-[15px] h-[15px] md:w-[20px] md:h-[20px] cursor-pointer absolute right-[20px] top-[20px]"
          >
            <IoMdSend className="text-[#5A3D8B]"/>
          </button>
      </form>
      </div>
    </div>
  );
};

export default AskQuestionModal;
