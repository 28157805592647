import React from "react";

const TermsConditions = () => {
  return (
    <div className="mt-[160px] md:mt-[176px] font-dmsans mb-[60px] md:mb-[133px]">
      <div className="flex flex-col items-center justify-center gap-1 mb-[32px]">
        <h3 className="font-[400] text-[15px] md:text-[20px] leading-[28px] text-center text-lightGray uppercase">
          Understanding our
        </h3>
        <h1 className="font-spaceGrotesk font-[700] text-[30px] md:text-[60px] leading-[45px] md:leading-[70px] text-center text-[#161414]">
          Terms and Conditions
        </h1>
      </div>
      <div className="mx-6 md:mx-[80px]">
        <div>
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            1. Agreement
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            1.1  This Agreement sets forth the terms and conditions ("Terms")
            governing your access to and use of the Hamzaa B2B Auto Repair Shop
            SAAS CRM software and related services ("Services"), provided by
            Hamzaa Technologies ("Hamzaa").
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            1.2 By accessing or using the Services, you ("Customer") agree to be
            bound by these Terms. If you do not agree to these Terms, you may
            not access or use the Services.
          </p>
        </div>
        <div className="my-[24px]">
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            2. Services
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            2.1 The Services provide Customer with a cloud-based software
            platform for managing various aspects of their auto repair shop
            business, including but not limited to:
          </p>
          <ul className="list-disc">
            <li className="font-[400] text-[20px] leading-[28px] text-darkGray">
              Customer relationship management (CRM){" "}
            </li>
            <li className="font-[400] text-[20px] leading-[28px] text-darkGray">
              Appointment scheduling{" "}
            </li>
            <li className="font-[400] text-[20px] leading-[28px] text-darkGray">
              Inventory management{" "}
            </li>
            <li className="font-[400] text-[20px] leading-[28px] text-darkGray">
              Service history tracking{" "}
            </li>
            <li className="font-[400] text-[20px] leading-[28px] text-darkGray">
              Reporting and analytics
            </li>
          </ul>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            2.2 Hamzaa reserves the right to modify, suspend, or discontinue the
            Services, or any portion thereof, at any time and for any reason,
            with or without notice.
          </p>
        </div>
        <div>
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            3. Customer Obligations
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            3.1 Customer shall:
          </p>
          <ul className="list-disc">
            <li className="font-[400] text-[20px] leading-[28px] text-darkGray">
              Provide accurate and complete information during the registration
              process and maintain the accuracy of such information.{" "}
            </li>
            <li className="font-[400] text-[20px] leading-[28px] text-darkGray">
              Comply with all applicable laws and regulations in connection with
              their use of the Services.{" "}
            </li>
            <li className="font-[400] text-[20px] leading-[28px] text-darkGray">
              Not use the Services for any illegal or unauthorized purpose,
              including but not limited to:{" "}
            </li>
            <li className="font-[400] text-[20px] leading-[28px] text-darkGray">
              Infringing on the intellectual property rights of others.{" "}
            </li>
            <li className="font-[400] text-[20px] leading-[28px] text-darkGray">
              Interfering with the operation of the Services.{" "}
            </li>
            <li className="font-[400] text-[20px] leading-[28px] text-darkGray">
              Using the Services in a manner that could harm Hamzaa or its
              reputation.{" "}
            </li>
            <li className="font-[400] text-[20px] leading-[28px] text-darkGray">
              Not attempt to circumvent or bypass any security measures
              implemented by Hamzaa.{" "}
            </li>
            <li className="font-[400] text-[20px] leading-[28px] text-darkGray">
              Not use the Services to transmit any viruses, worms, malware, or
              other harmful code.{" "}
            </li>
            <li className="font-[400] text-[20px] leading-[28px] text-darkGray">
              Protect their account credentials and not share them with
              unauthorized parties.
            </li>
          </ul>
        </div>
        <div className="my-[24px]">
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            4. Intellectual Property
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            4.1 The Services and all related intellectual property rights,
            including but not limited to copyrights, trademarks, and patents,
            are owned by Hamzaa or its licensors.
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            4.2 Customer acknowledges and agrees that no right, title, or
            interest in or to the Services or any intellectual property rights
            therein is transferred to Customer.
          </p>
        </div>
        <div>
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            5. Data Privacy and Security
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            5.1 Hamzaa will take reasonable measures to protect Customer Data
            (as defined below) from unauthorized access, use, or disclosure.
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            5.2 Customer is responsible for the security of its own account
            credentials and for maintaining the confidentiality of its Customer
            Data.
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            5.3 "Customer Data" means any data, information, or content
            uploaded, transmitted, or stored by Customer using the Services.
          </p>
        </div>
        <div className="my-6">
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            6. Disclaimer of Warranties
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            6.1 THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT
            WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
            TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            6.2 HAMZAA DOES NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED,
            ERROR-FREE, OR SECURE, OR THAT DEFECTS WILL BE CORRECTED.
          </p>
        </div>
        <div>
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            7. Limitation of Liability
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            7.1 IN NO EVENT SHALL HAMZAA BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
            SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT
            LIMITED TO LOSS OF PROFITS, DATA LOSS, OR BUSINESS INTERRUPTION,
            ARISING OUT OF OR RELATING TO THE USE OF OR INABILITY TO USE THE
            SERVICES, EVEN IF HAMZAA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES.
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            7.2 HAMZAA'S TOTAL LIABILITY TO CUSTOMER FOR ALL DAMAGES ARISING OUT
            OF OR RELATING TO THESE TERMS OR THE SERVICES SHALL NOT EXCEED THE
            AMOUNT PAID BY CUSTOMER TO HAMZAA FOR THE SERVICES IN THE TWELVE
            (12) MONTHS PRECEDING THE CLAIM.
          </p>
        </div>
        <div className="my-6">
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            8. Termination
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            8.1 This Agreement may be terminated by either party upon written
            notice to the other party.
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            8.2 Hamzaa may terminate this Agreement immediately upon notice to
            Customer if Customer breaches any of these Terms.
          </p>
        </div>
        <div>
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            9. Governing Law
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            9.1 These Terms shall be governed by and construed in accordance
            with the laws of Canada.
          </p>
        </div>
        <div className="my-6">
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            10. Dispute Resolution
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            10.1 Any dispute arising out of or relating to these Terms shall be
            subject to mediation.
          </p>
        </div>
        <div>
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            11. Entire Agreement
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            11.1 These Terms constitute the entire agreement between the parties
            with respect to the subject matter hereof and supersede all prior or
            contemporaneous communications, representations, and understandings,
            whether oral or written.
          </p>
        </div>
        <div className="my-6">
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            12. Severability
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            12.1 If any provision of these Terms is held to be invalid or
            unenforceable, such provision shall be struck and the remaining
            provisions shall be enforced.
          </p>
        </div>
        <div>
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            13. Changes to Terms
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            13.1 Hamzaa may update these Terms from time to time. Customer will
            be notified of any material changes to these Terms.
          </p>
        </div>
        <div className="my-6">
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            14. Contact Information
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            14.1 Please contact Hamzaa at support@hamzaa.ca with any questions
            regarding these Terms.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
