import React, { useEffect, useState, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import AboutUs from "./pages/AboutUs";
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import ContactUs from "./pages/ContactUs";
import Footer from "./components/Footer";
import Features from "./pages/Features";
import Avatar from "./assets/images/chat-image.png";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import Subscribe from "./components/Subscribe";
import Careers from "./pages/Careers";
import RequestAnalyst from "./pages/RequestAnalyst";
import Referral from "./pages/Referral";
import NeedHelp from "./components/NeedHelp";
import AskQuestionModal from "./components/AskQuestionModal";
import { FaX } from "react-icons/fa6";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  const location = useLocation();
  const [isInFooter, setIsInFooter] = useState(false);
  const [chatButtonOpen, setChatButtonOpen] = useState(true);
  const [isNeedHelpOpen, setIsNeedHelpOpen] = useState(false);
  const [isChatBoxOpen, setIsChatBoxOpen] = useState(false);
  const chatButtonRef = useRef(null);
  const footerRef = useRef(null);

  const handleChatButton = (e) => {
    e.preventDefault();
    setChatButtonOpen(false);
    setIsNeedHelpOpen(true);
  };

  const handleExitHelpModal = (e) => {
    e.preventDefault();
    setIsNeedHelpOpen(false);
setChatButtonOpen(true);
setIsChatBoxOpen(false);
 }

 const handleChatBox = (e) => {
  e.preventDefault();
setIsNeedHelpOpen(false);
setIsChatBoxOpen(true);

 }

  useEffect(() => {
    const footerElement = footerRef.current; // Copy the ref to a local variable

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInFooter(entry.isIntersecting); // Set true when in footer
      },
      { threshold: 0.1 }
    );

    if (footerElement) {
      observer.observe(footerElement);
    }

    return () => {
      if (footerElement) {
        observer.unobserve(footerElement);
      }
    };
  }, []);

  return (
    <>
      <div className="max-w-full">
        <Navbar />{" "}
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Home chatButtonRef={chatButtonRef} isInFooter={isInFooter} />
            }
          />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/features" element={<Features />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsConditions />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/request-analyst" element={<RequestAnalyst />} />
          <Route path="/referral" element={<Referral />} />
        </Routes>
        {
          location.pathname !== "/privacy" &&
          location.pathname !== "/terms" && (
            <>
           { chatButtonOpen &&
              <div
                onClick={handleChatButton}
                className={`fixed bottom-[50px] right-6 flex items-center gap-[11px] w-[160px] h-[48px] rounded-[25px] py-[15px] px-[8px] cursor-pointer z-30 ${
                  isInFooter ? "bg-white" : "bg-foot"
                }`}
              >
                <img src={Avatar} alt="Avatar" className="w-[40px] h-[40px]" />
                <button type="button"
                  className={`font-[500] text-[14px] leading-[18px] ${
                    isInFooter ? "text-black" : "text-white"
                  }`}
                >
                  Let's Chat
                </button>
              </div>}
              {isNeedHelpOpen && 
              <div className="flex flex-col gap-4">
              <NeedHelp handleExitHelpModal={handleExitHelpModal} handleChatBox={handleChatBox} />
             <button type="button" onClick={handleExitHelpModal} className="fixed bottom-[50px] right-6 z-50 "> 
             <div className="bg-[#00325E] rounded-full w-[56px] h-[56px] flex justify-center items-center">
             <FaX className="w-[30px] h-[30px] text-white"/>
             </div>
             </button>
              </div>}
              {
                isChatBoxOpen && <div className="flex flex-col gap-4">
            <AskQuestionModal/>
             <button type="button" onClick={handleExitHelpModal} className="fixed bottom-[50px] right-6 z-50 "> 
             <div className="bg-[#00325E] rounded-full w-[56px] h-[56px] flex justify-center items-center">
             <FaX className="w-[30px] h-[30px] text-white"/>
             </div>
             </button>
              </div>
              }
            </>
           
          )}
        <Subscribe />
        <Footer footerRef={footerRef} />
      </div>
    </>
  );
}

export default App;
