import React from 'react'

const Referral = () => {
  return (
    <div className="mt-[160px] mb-[60px] md:mt-[176px] font-dmsans md:mb-[133px]">
      <div className="flex flex-col items-center justify-center gap-1 mb-[32px]">
        <h3 className="font-[400] text-[15px] md:text-[20px] leading-[28px] text-center text-lightGray uppercase">
          Understanding our
        </h3>
        <h1 className="font-spaceGrotesk font-[700] text-[30px] md:text-[60px] leading-[45px] md:leading-[70px] text-center text-[#161414]">
          Referral Program
        </h1>
      </div>
      <div className="mx-6 md:mx-[80px]">
        <h4 className="mb-[30px] font-[400] text-[20px] leading-[28px] text-lightGray">
          Last updated: September 20, 2023
        </h4>
        <h5 className="font-[400] text-[20px] leading-[28px] text-darkGray mb-[30px]">
          These Terms and Conditions (“Terms”) and the Referral Program (as
          defined below) are effective from and after August 31, 2023 (the
          “Effective Date”). The Hamzaa Referral Program (“Referral Program”)
          allows those with contacts in the automotive services industry
          (“Referrers” or “you”) to earn rewards for finding potential customers
          for Hamzaa Products and Services, subject to these Terms;
        </h5>
        <div>
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            1. DEFINITIONS
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            1.1  “Incentive” means one (1) $200 Gift Card per Referred Customer.
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            1.2  “Demo” means a demonstration led by Hamzaa representatives of
            Hamzaa’s  software-as-a-service automotive care solutions committed
            to and actually attended by one or more representatives of a
            Referred Customer.
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            1.3   “Referred Customer” means a new customer brought to Hamzaa’s
            attention through the Referral Program and that, in Hamzaa’s sole
            judgment, satisfies the following conditions:
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            (a) The customer must have been submitted into the Referral Program
            by submission through the Referral Program dashboard, through the
            provision by you an active customer or by other means identified
            there;
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            (b) The customer cannot be an existing customer or a previous
            customer of Hamzaa;
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            (c) The customer cannot be in the Sales Process at the time of the
            referral or during the prior two (2) months;
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            (d) The customer must not have been previously submitted to the
            Referral Program by you or another party during the prior two (2)
            months;
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            (e) The customer must not have been submitted to any other discount
            or incentive program operated by Hamzaa or a referral of such
            customer otherwise compensated, and
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            (f) The customer must attend a Demo within two (2) months of
            submission.
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            1.4 “Sales Process” means the communications and processes in which
            Hamzaa engages a potential customer with the intention to sell
            Hamzaa Products and Services.
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            1.5 “Hamzaa Products and Services” means the products and services
            offered by Hamzaa, including, but not limited to those described on
            the Hamzaa Site.
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            1.6 “Hamzaa Site” means Hamzaa.ca or any subdomain or other site
            controlled by Hamzaa where it offers the Hamzaa Products and
            Services, and all successors to such sites.
          </p>
        </div>
        <div className="my-[24px]">
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            2. OBLIGATIONS; EXCEPTIONS AND EXCLUSIONS
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            2.1 Referral Program Obligations. Neither party has any obligation
            to perform in the Referral Program. Hamzaa reserves the right to
            refuse to sell Hamzaa Products and Services to any person for any
            reason. In addition, Hamzaa in its sole discretion may cease selling
            Hamzaa Products and Services at any time to any customer including
            those referred by Referral Company for any reason in accordance with
            Hamzaa’s agreements with those customers.
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            2.2   Incentives.
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            (a) For each Referred Customer you successfully refer to Hamzaa in
            accordance with these terms, Hamzaa will give the specified
            Incentive.   
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            (b) Incentives will be delivered by e-mail as electronic gift cards
            no later than the thirty (30) days following the end of the calendar
            month in which they are awarded.
          </p>
        </div>
        <div className="mb-[24px]">
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            3. REPRESENTATIONS AND WARRANTIES.
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            By participating in the Referral Program, you represent and warrant
            that: (a) you have the full right, power and authority to do so and
            to enter into these Terms and to perform your obligations hereunder;
            (b) your participation in the Referral Program does not and will not
            conflict with or result in a breach (including with the passage of
            time) of any other agreement to which you are a party; and (c) these
            Terms constitute you valid and binding agreement and are enforceable
            against you (except as may be limited by public policy or creditors’
            rights generally).
          </p>
        </div>
        <div className="mb-[24px]">
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            4. INDEMNIFICATION.
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            You will indemnify, defend, and hold harmless Hamzaa and its
            directors, officers, and employees from and against all taxes,
            losses, damages, liabilities, costs, and expenses, including
            attorneys’ fees and other legal expenses, arising directly or
            indirectly from or in connection with: (a) any failure by you to
            participate in the Referral Program in accordance with all
            applicable laws, rules, and regulations, (b) any violation or
            claimed violation of a third party’s rights resulting in whole or in
            part from Hamzaa’s use of your work product under these Terms; or
            (c) from your failure to be classified as an independent contractor
            with respect to the Referral Program.
          </p>
        </div>
        <div className="mb-[24px]">
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            5. DISCLAIMER OF WARRANTIES.
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            HAMZAA MAKES NO REPRESENTATIONS OR WARRANTIES TO REFERRER WITH
            RESPECT TO THE HAMZAA PRODUCTS AND SERVICES, INCLUDING ANY WARRANTY
            OF MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE.
          </p>
        </div>
        <div className="mb-[24px]">
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            6. LIMITATION OF LIABILITY.
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            NEITHER PARTY SHALL BE LIABLE TO THE OTHER PARTY FOR ANY SPECIAL,
            CONSEQUENTIAL OR INCIDENTAL DAMAGES, WHETHER UNDER TORT, CONTRACT OR
            ANY OTHER THEORY, EVEN IF SUCH PARTY HAS BEEN INFORMED OF THE
            POSSIBILITY OF SUCH DAMAGES. EXCEPT FOR THE INDEMNIFICATION
            OBLIGATIONS DESCRIBED HEREIN, IN NO EVENT SHALL EITHER PARTY’S TOTAL
            LIABILITY ARISING OUT OF OR RELATED TO THESE TERMS AND THE REFERRAL
            PROGRAM EXCEED THE AGGREGATE OF THE AMOUNTS PAID OR PAYABLE BY
            EITHER PARTY TO THE OTHER UNDER THIS AGREEMENT IN THE TWELVE (12)
            MONTHS PRIOR TO ANY SUCH CLAIM OF LIABILITY.
          </p>
        </div>
        <div className="mb-[24px]">
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            7. TERM AND TERMINATION
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            7.1   Term. These Terms and the Referral Program shall commence on
            the Effective Date and shall continue through December 31, 2024;
            provided, however, that this Referral Program may be amended at any
            time or from time to time by Hamzaa, Inc. at its sole and absolute
            discretion.
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            7.2   Termination. Without prejudice to any other right or remedy
            available at law or in equity in respect of any event described
            below, the Referral Program may be terminated by Hamzaa at any time
            and without penalty.  Such a termination will not relieve Hamzaa of
            liability for payment of Incentives payable prior to termination.
          </p>
        </div>
        <div className="mb-[24px]">
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            8. DISPUTE RESOLUTION
          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            8.1   Arbitration. Except as set forth in Section 8.4 below, any
            dispute or controversy arising out of, relating to, or concerning
            any interpretation, or concerning any interpretation, construction,
            performance, or breach of these Terms, will be settled by
            arbitration to be held in San Jose, California in accordance with
            the rules then in effect of the American Arbitration Association.
            The arbitrator may grant injunctions or other relief in the dispute
            or controversy. The decision of the arbitrator will be final,
            conclusive, and binding on the Parties to the arbitration. Judgment
            may be entered on the arbitrator's decision in any court having
            jurisdiction.
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            8.2   Arbitration Fees. In the event of any arbitration the Parties
            hereto arising from or related to a party's performance or breach of
            these Terms, the prevailing party shall be entitled to and shall
            receive, in addition to any relief granted by the arbitrator, their
            reasonable attorneys' fees and other costs and expenses incurred in
            prosecuting or opposing the prosecution of such action. Further, if
            any action at law or in equity is necessary to enforce or interpret
            the terms of these Terms, the prevailing party shall be entitled to
            reasonable attorneys' fees, costs and necessary disbursement, in
            addition to any other relief to which the party may be entitled.
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            8.3   Waiver or Right to Jury Trial. This arbitration clause
            constitutes a waiver of each Party’s right to a jury trial for all
            disputes relating to all aspects of these Terms and the Referral
            Program.
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            8.4   Equitable Remedies. The Parties may apply to any court of
            competent jurisdiction for a temporary restraining order,
            preliminary injunction, or other interim or conservatory relief, as
            necessary, without breach of these Terms and without abridgement of
            the powers of the arbitrator.
          </p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">
            8.5   Consideration. Each party's promise to resolve claims by
            arbitration in accordance with the provisions of these Terms, rather
            than through the courts, is consideration for the other parties like
            promise.
          </p>
        </div>
        <div className="mb-[24px]">
          <h4 className="font-[700] text-[20px] leading-[28px] text-darkGray">
            9. MISCELLANEOUS

          </h4>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">9.1     Non assignment and No Subcontractors. Neither these Terms nor any rights under these Terms may be assigned or otherwise transferred by you, in whole or in part, whether voluntarily or by operation of law, without the prior written consent of Hamzaa. Subject to the foregoing, these Terms will be binding upon and will inure to the benefit of the parties and their respective successors and assigns. Any assignment in violation of the foregoing will be null and void.
</p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">9.2     Waiver. Any waiver of the provisions of these Terms by Hamzaa or of Hamzaa’s rights or remedies under these Terms must be in writing to be effective.
</p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">9.3     Severability. If any term, condition, or provision in these Terms is found to be invalid, unlawful, or unenforceable to any extent, the parties will endeavor in good faith to agree to amendments that will preserve, as far as possible, the intentions expressed in this Agreement.
</p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">9.4     Governing Law. The internal laws of California, but not the choice of law rules, govern this Agreement.
</p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">9.5     Headings. Headings are used in these Terms for reference only and will not be considered when interpreting this Agreement.
</p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">9.6     Notices. All notices, approvals, consents, waivers, and other communications under these Terms must be in writing and will be deemed to have been given when (a) in the case of Hamzaa, when received, if sent by a nationally recognized overnight delivery service or U.S. mail to 155 E, Main Ave, Suite 150, Morgan Hill, CA 95037; or (b) in the case of Referrer, when sent by email to the addresses, facsimile numbers and/or email addresses provided with submission of the Referred Customer to which the communication relates or to such other addresses, facsimile numbers or email addresses as a party may designate by notice to the other party from time to time.
</p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">9.7     Independent Contractors. The relationship between the parties is that of independent contractors. Nothing in this Agreement shall be deemed to create an employment, partnership, joint venture or agency relationship between the parties.
</p>
          <p className="font-[400] text-[20px] leading-[28px] text-darkGray">9.8     Entire Agreement. These Terms represent the complete agreement of the parties, supersedes all prior discussions, communications and agreements between the parties with respect to the subject matter hereof, and cannot be amended or modified except in a writing signed by both parties.</p>
        </div>
      </div>
    </div>
  )
}

export default Referral