import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import InputField from "../components/InputField";
import mail from "../assets/images/mail-copy.svg";
import user from "../assets/images/user.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const RequestDemo = ({ closeModal }) => {
  const [phone, setPhone] = useState("");
  const [businessCategory, setBusinessCategory] = useState("");
  const [businessSize, setBusinessSize] = useState("");
  const [dateValue, setDateValue] = useState("");
  const categories = [
    { label: "Automobile Repair Shop", value: "Automobile Repair Shop" },
    { label: "Automobile Tire shop", value: "Automobile Tire shop" },
    {
      label: "Automobile body and collision",
      value: "Automobile body and collision",
    },
    {
      label: "Automobile Oil change shop",
      value: "Automobile Oil change shop",
    },
    { label: "Mobile Auto repair shop", value: "Mobile auto repair shop" },
    { label: "Motorcycle repair shop", value: "Motorcycle repair shop" },
    { label: "Bicycle shop", value: "Bicycle shop" },
    { label: "Heavy equipment shop", value: "Heavy equipment shop" },
    { label: "Electric vehicle shop", value: "Electric vehicle shop" },
    {
      label: "Medium and Heavy-duty shop",
      value: "Medium and Heavy-duty shop",
    },
    { label: "Marine shop", value: "	Marine shop" },
    { label: "Parts and accessories", value: "Parts and accessories" },
    { label: "Performance shop", value: "Performance shop" },
    { label: "RV Shop", value: "RV Shop" },
    { label: "Others", value: "Others" },
  ];

  const sizes = [
    { label: "Small", value: "Small" },
    { label: "Medium", value: "Medium" },
    { label: "Large", value: "Large" },
  ];

  const handleOnChange = (value) => {
    setPhone(value); // value contains the full phone number including the country code
  };
  const [inputStyles, setInputStyles] = useState({});
  const [buttonStyles, setButtonStyles] = useState({});

  const updateStyles = () => {
    if (window.innerWidth <= 640) {
      // Styles for smaller screens
      setInputStyles({
        width: "100%",
        height: "50px",
        borderRadius: "10px",
        padding: "10px 16px 10px 70px",
        fontSize: "14px",
        fontWeight: "400",
        color: "#1A2233",
        borderColor: "#D9D9D9",
      });
      setButtonStyles({
        width: "60px",
        height: "50px",
        padding: "8px 8px",
        borderRight: "1px solid #e3e3e3",
        backgroundColor: "#F2F2F2",
        borderRadius: "10px 0 0 10px",
      });
    } else {
      // Styles for larger screens
      setInputStyles({
        width: "100%",
        height: "70px",
        borderRadius: "10px",
        padding: "16px 16px 16px 90px",
        fontSize: "15px",
        fontWeight: "400",
        color: "#1A2233",
        border: "1px solid #D9D9D9",
      });
      setButtonStyles({
        width: "73px",
        height: "70px",
        padding: "12px 10px",
        borderRight: "0px solid #e3e3e3",
        backgroundColor: "#F2F2F2",
        borderRadius: "10px 0 0 10px",
        border: "none",
      });
    }
  };

  useEffect(() => {
    // Set initial styles based on screen size
    updateStyles();
    // Add event listener to update styles on screen resize
    window.addEventListener("resize", updateStyles);
    return () => window.removeEventListener("resize", updateStyles);
  }, []);
  return (
    <div
      onClick={closeModal}
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white md:rounded-[12px] w-full  h-full md:max-h-[90%] overflow-auto scrollbar-thin scrollbar-thumb-scrollbar-thumb hover:scrollbar-thumb-scrollbar-thumb-hover   md:max-w-[580px] relative z-10 border "
      >
        <div className="absolute top-5 right-3 md:hidden w-[48px] h-[48px] rounded-[14px] bg-[#F9F9F9] flex justify-center items-center">
          <IoClose
            onClick={closeModal}
            className="text-[#292D32] w-[17px] h-[17px] cursor-pointer"
          />
        </div>
        <h1 className="block md:hidden text-[32px] font-[400] leading-[29px] font-miltonian text-[#00325E] bg-white text-center pt-[30px] pb-[44px]">
          Hamzaa
        </h1>
        <div className="flex justify-between items-center md:py-8 px-3 md:px-[60px] md:items-start md:sticky md:top-0 z-30 bg-white">
          <h1 className="font-spaceGrotesk font-[500] text-[24px] leading-[40px] md:leading-[52px] text-[#1C1C1C] tracking-[0.3px] ">
            Get a Quick Demo of Hamzaa
          </h1>
          <div className="hidden w-[48px] h-[48px] rounded-[14px] bg-[#F9F9F9] md:flex justify-center items-center">
            <IoClose
              onClick={closeModal}
              className="text-[#292D32] w-[17px] h-[17px] cursor-pointer"
            />
          </div>
        </div>
        <form className="flex flex-col gap-4 pt-[20px] pb-[60px] px-3 md:px-[60px]  w-full">
          <div className="flex flex-col md:flex-row items-center gap-[12px] w-full">
            <InputField
              label="First Name"
              name="firstName"
              type="text"
              placeholder="Enter your name"
              postfix={
                <img src={user} alt="User icon" className="w-[20px] h-[24px]" />
              }
              className="w-full flex flex-col"
              labelClassName="!font-[300] !text-[14px] !leading-[18px] !text-[#020202]"
              inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px] border-[#D9D9D9]"
            />
            <InputField
              label="Last Name"
              name="lastName"
              type="text"
              placeholder="Enter your name"
              postfix={
                <img src={user} alt="User icon" className="w-[20px] h-[24px]" />
              }
              className="w-full flex flex-col"
              labelClassName="!font-[300] !text-[14px] !leading-[18px] !text-[#020202]"
              inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px] border-[#D9D9D9]"
            />
          </div>
          <InputField
            label="Email"
            name="Email"
            type="email"
            placeholder="Enter your email address"
            postfix={
              <img src={mail} alt="Email icon" className="w-[20px] h-[16px]" />
            }
            className="w-full flex flex-col"
            inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px] border-[#D9D9D9]"
            labelClassName="!font-[300] !text-[14px] !leading-[18px] !text-[#020202]"
          />
          <div className="flex flex-col gap-2">
            <label
              htmlFor="phoneNumber"
              className="font-[400] text-[14px] leading-[18px] text-[#020202] pb-[4px] block"
            >
              Phone Number
            </label>
            <PhoneInput
              country={"ca"} // Default country code
              value={phone}
              onChange={handleOnChange}
              placeholder="Enter your number"
              enableSearch={true} // Enables search functionality
              searchPlaceholder="Search countries"
              inputStyle={inputStyles}
              buttonStyle={buttonStyles}
              buttonClass="custom-flag-dropdown"
            />
          </div>
          <InputField
            label="Business Name"
            name="businessName"
            type="text"
            placeholder="Enter your business name"
            className="w-full flex flex-col"
            labelClassName="!font-[300] !text-[14px] !leading-[18px] !text-[#020202]"
            inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px] border-[#D9D9D9]"
          />
          <InputField
            label="Business Size"
            name="businessSize"
            handleValue={setBusinessSize}
            value={businessSize}
            type="select"
            options={sizes}
            placeholder="Select"
            className="w-full flex flex-col gap-2"
            selectClassName="w-full flex justify-center items-center rounded-[12px] md:rounded-[13px] h-[58px] md:h-[69px]"
          />
          <InputField
            label="Business Category"
            name="businessName"
            handleValue={setBusinessCategory}
            value={businessCategory}
            type="select"
            options={categories}
            placeholder="Select"
            className="w-full flex flex-col gap-2"
            selectClassName="w-full flex justify-center items-center rounded-[12px] md:rounded-[13px] h-[58px] md:h-[69px]"
          />
          <InputField
            asteriks={true}
            label="Calendar"
            type="calendar"
            name="date"
            value={dateValue}
            handleValue={setDateValue}
            placeholder="Select a date"
            onClick={setDateValue}
          />

          <InputField
            label="Time"
            name="time"
            type="time"
            asteriks={true}
            placeholder="Pick a time"
            className="w-full flex flex-col gap-2"
            inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px]"
            handleValue={(time) => console.log("Selected time:", time)}
          />
          <div className="flex justify-center items-center ">
            <button className="mt-[24px] w-full h-[52px] md:h-[60px] border-[2px] border-foot rounded-[8px] py-[18px] px-[18px] flex justify-center items-center bg-primary  font-[700] text-[16px] leading-[24px] text-center text-white">
              Get a Demo
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RequestDemo;
