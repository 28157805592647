import React from "react";
import NexPart from '../assets/images/nex-part.png'
import Google from '../assets/images/google.png'
import Intuit from '../assets/images/intuit.png'
import NewsData from '../assets/images/news-data.png'
import CarFax from '../assets/images/car-fax.png'
import Napa from '../assets/images/napa.png'
import PartsAvatar from '../assets/images/parts-avatar.png'
import PartsTech from '../assets/images/parts-tech.png'
// import "react-lazy-load-image-component/src/effects/blur.css";
// import { LazyLoadImage } from "react-lazy-load-image-component";



const Companies = () => {
  // const LazyCompanyWebImage = ({ src, alt }) => {
  //   return <LazyLoadImage alt={alt} src={src}   effect="blur" />;
  // };
  // const LazyCompanyMobileImage = ({ src, alt}) => {
  //   return <LazyLoadImage alt={alt} src={src} width={140} height={52} effect="blur" />;
  // };
  const companies = [
   {image: NexPart},
   {image: Google},
   {image: Intuit},
   {image: NewsData},
   {image: CarFax},
   {image: Napa},
   {image: PartsAvatar},
   {image: PartsTech}
  ];
  return (
    <div className="bg-[#F6F9FF] h-auto w-full pt-[32px] px-[32px] md:py-[80px] flex flex-col gap-[40px] md:gap-[53px] items-center font-dmsans">
      <div className="flex justify-center items-center w-full md:w-[524px]">
        <h2 className="font-[500] text-[24px] leading-[40px] md:text-[36px] md:leading-[47px] tracking-[0.3px] text-center text-[#161414]">
        5+ Powerful Integration
        </h2>
      </div>
      <div className="hidden md:flex items-center justify-center gap-[32px] md:gap-[90px] md:flex-wrap md:mx-[80px]">
        {companies.map((company, index) => (
<div  key={index} className="flex justify-center items-center min-h-[40px] max-h-[52px]">
<img data-aos="zoom-in" src={company.image} alt={`${company.image}`} className="w-[86px] h-full" />
{/* <LazyCompanyWebImage src={company.image} alt={`${company.image}`} /> */}
</div>
        ))}
        </div>
        <div className="md:hidden flex items-center gap-8 w-full mb-[50px] overflow-x-auto no-scrollbar scroll-smooth ">
        {companies.map((company, index) => (
< >
<img data-aos="zoom-in" key={index} src={company.image} alt={`${company.image}`} className="w-auto  h-[35px]" />
{/* <LazyCompanyMobileImage src={company.image} alt={`${company.image}`} /> */}
</>
        ))}
        </div>
    </div>
  );
};

export default Companies;
